
import React from 'react'

import Meta from 'layout/Meta'


export default function NotFoundPage() {
	return <main>

		<Meta title="Page not found" />

		<h1>Page not found</h1>
		
	</main>
}
